var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "yqg-video" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "video-title" }, [
          _vm._v("\n            中国人民银行征信中心创意宣传片展示\n        "),
        ]),
        _vm._v(" "),
        _c("video", {
          staticClass: "video",
          attrs: {
            id: "video",
            src: "https://public.yangqianguan.com/upload/yqg-mayuri-web/ll6Vgh2uDbLtyxwByGCP9WWV6Bsg.m4v",
            controls: "",
            preload: "",
            controlslist: "nodownload",
            poster:
              "https://public.yangqianguan.com/upload/yqg-mayuri-web/Fnsn51Jiy81x2qEfu5gE295bt9tk.png",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "banner",
          attrs: {
            src: "https://public.yangqianguan.com/upload/yqg-mayuri-web/Fh6x_iJEq3ACH8zBoODoUfzfpUkG.jpg",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }